import React, { useState } from "react";
import "./index.scss";
import moment from "moment";
import { convertBytes } from "../../../helpers";
import {
  checkFileFormat,
  removeFileExtension,
} from "../../../helpers/fileProcessing";
import {
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledMenu } from "../../../components/Menu/styled-menu";
import DeleteIcon from "@mui/icons-material/Delete";
import { Download, Edit, Send, Visibility } from "@mui/icons-material";
import PublicOffRoundedIcon from "@mui/icons-material/PublicOffRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";

const userType = sessionStorage.getItem("user_type");

const MyDocumentRow = ({
  row,
  onEditClick,
  onDeleteClick,
  onPublicClick,
  onPrivateClick,
  onRequestApprovalClick,
  onPreviewClick,
  onDownloadClick,
}) => {
  // MENU FOR DOCUMENTS
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const canDelete = userType?.toLowerCase() === "system_admin";
  const canRename = userType?.toLowerCase() === "system_admin";
  const canSubmit = userType?.toLowerCase() === "system_admin";
  const canMakePublic =
    userType?.toLowerCase() === "system_admin" && row.visibility === "PRIVATE";
  const canMakePrivate =
    userType?.toLowerCase() === "system_admin" && row.visibility === "PUBLIC";

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // END OF MENU

  return (
    <tr>
      <td>
        <div className="d-flex alig-items-center">
          <div className="info">
            <img src={checkFileFormat(row.contentType)} alt="" />
            <div>
              <Tooltip title={row.originalFilename}>
                <Typography
                  noWrap
                  variant="h4"
                  sx={{
                    maxWidth: 300,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {removeFileExtension(row.originalFilename)}
                </Typography>
              </Tooltip>
              <span>
                {moment(row.uploadDate).format("MMM Do YYYY, h:mm:ss a")}
              </span>
            </div>
          </div>
        </div>
      </td>
      <td>{convertBytes(row.size)}</td>

      <td>
        {row.approvalRequests.map((request) => {
          let chipColor;
          const textColor = "#F7F7F7";
          let tooltip = "";
          const managers = request.project.managers
            .map((manager) => `${manager.firstName} ${manager.lastName}`)
            .join(", ");

          switch (request.status) {
            case "PENDING":
              chipColor = "#ed6c02";
              tooltip = !managers
                ? "Awaiting review"
                : `Awaiting review from ${managers}`;
              break;
            case "APPROVED":
              chipColor = "#2e7d32";
              tooltip =
                !request.approvedBy?.firstName || !request.approvedBy.lastName
                  ? "Approved"
                  : `Approved by ${request.approvedBy.firstName} ${request.approvedBy.lastName}`;
              break;
            case "DECLINED":
              chipColor = "#d32f2f";
              tooltip =
                !request.disapprovedBy?.firstName ||
                !request.disapprovedBy?.lastName
                  ? "Request declined"
                  : `Request declined by ${request.disapprovedBy.firstName} ${request.disapprovedBy.lastName}`;
              break;
            default:
              chipColor = "#9E9E9E";
              break;
          }
          return (
            request.status === "APPROVED" && (
              <Tooltip title={tooltip} key={request.id}>
                <Chip
                  key={request.id}
                  label={request.project.name}
                  variant="filled"
                  size="small"
                  sx={{
                    marginRight: 1,
                    marginBottom: 0,
                    backgroundColor: chipColor,
                    color: `${textColor}`,
                    "& .MuiChip-label": {
                      color: "inherit", // Force inheritance
                    },
                  }}
                />
              </Tooltip>
            )
          );
        })}
      </td>
      <td className="action">
        <Tooltip title="Manage">
          <IconButton onClick={handleClickMenu} className="no-style">
            <MoreVertIcon
              id="basic-button"
              aria-controls={open ? "manage-document-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            />
          </IconButton>
        </Tooltip>
        <StyledMenu
          id="manage-document-menu"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onPreviewClick(row);
            }}
          >
            <ListItemIcon>
              <Visibility fontSize="small" />
            </ListItemIcon>
            <ListItemText>Preview</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onDownloadClick(row);
            }}
          >
            <ListItemIcon>
              <Download fontSize="small" />
            </ListItemIcon>
            <ListItemText>Download</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              handleCloseMenu();
              onRequestApprovalClick(row);
            }}
            disabled={!canSubmit}
          >
            <ListItemIcon>
              <Send fontSize="small" />
            </ListItemIcon>
            <ListItemText>Submit for Approval</ListItemText>
          </MenuItem>

          {canRename && (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onEditClick(row);
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              <ListItemText>Rename</ListItemText>
            </MenuItem>
          )}

          {canMakePublic && (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onPublicClick(row);
              }}
            >
              <ListItemIcon>
                <PublicRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Make Public</ListItemText>
            </MenuItem>
          )}

          {canMakePrivate && (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onPrivateClick(row);
              }}
            >
              <ListItemIcon>
                <PublicOffRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Make Private</ListItemText>
            </MenuItem>
          )}

          {canDelete && (
            <MenuItem
              onClick={() => {
                handleCloseMenu();
                onDeleteClick(row);
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </StyledMenu>
      </td>
    </tr>
  );
};

export default MyDocumentRow;
