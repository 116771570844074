import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.scss";
import CloseIcon from "../../assets/images/svg/close.svg";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FolderIcon from "@mui/icons-material/Folder";
import InventoryIcon from "@mui/icons-material/Inventory";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import request from "../../utils/request";
import urls from "../../utils/config";
import { useAuth } from "../Auth/authProvider";

const Layout = ({ children, pageTitle }) => {
  const { logout, checkAuth } = useAuth();

  const [showMenu, setShowMenu] = useState(false);
  const userType = sessionStorage.getItem("user_type");

  const { API_BASE_URL } = urls || {};

  // Refresh user type whenever the state changes
  useEffect(() => {
    if (checkAuth()) {
      fetchUserDetails();
    }
  }, []);

  const fetchUserDetails = async () => {
    try {
      // get user data
      const sessionResponse = await request({
        method: "get",
        url: `${API_BASE_URL}/users/me`,
      });
      if (sessionResponse.success) {
        sessionStorage.setItem("user_type", sessionResponse.raw.data.roles[0]);
      } else {
        throw new Error(sessionResponse.raw.data.error.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const logOut = () => {
    logout();
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user_type");
    window.location.replace("/");
  };

  const contactUs = () => {
    window.location.href = `mailto:${process.env.REACT_APP_CONTACT_EMAIL}`;
  };

  return (
    <div className="layout__container">
      {/* sidebar area */}
      <section className={`sidebar ${showMenu ? "open" : ""}`}>
        <div className="sidebar__header">
          <img
            src={CloseIcon}
            alt=""
            className="close_icon"
            onClick={() => setShowMenu(false)}
          />
        </div>
        <ul>
          {checkAuth() ? (
            <li>
              <NavLink
                to="/overview"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <HomeRoundedIcon sx={{ mr: 1 }} />
                <span>Home</span>
              </NavLink>
            </li>
          ) : (
            <li>
              <NavLink
                to="/public"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>
                  <i className="icon-home"></i>
                </span>
                <span>Public</span>
              </NavLink>
            </li>
          )}

          {checkAuth() && (
            <li>
              <NavLink
                to="/data-entry"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <CreateNewFolderIcon sx={{ mr: 1 }} />
                <span>Data Entry</span>
              </NavLink>
            </li>
          )}

          {checkAuth() && (
            <li>
              <NavLink
                to="/project-documents"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FolderIcon sx={{ mr: 1 }} />
                <span>Project Documents</span>
              </NavLink>
            </li>
          )}

          {checkAuth() &&
            userType &&
            userType.toLowerCase() === "system_admin" && (
              <li>
                <NavLink
                  to="/projects"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <InventoryIcon sx={{ mr: 1 }} />
                  <span>Projects</span>
                </NavLink>
              </li>
            )}

          {checkAuth() &&
            userType &&
            (userType.toLowerCase() === "system_admin" ||
              userType.toLowerCase() === "management_staff") && (
              <li>
                <NavLink
                  to="/audit-approval"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <VerifiedUserIcon sx={{ mr: 1 }} />
                  <span>Audit & Approval</span>
                </NavLink>
              </li>
            )}

          {checkAuth() &&
            userType &&
            userType.toLowerCase() === "system_admin" && (
              <li>
                <NavLink
                  to="/user-management"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <GroupRoundedIcon sx={{ mr: 1 }} />
                  <span>Users</span>
                </NavLink>
              </li>
            )}

          <li onClick={() => contactUs()}>
            <p>
              <EmailRoundedIcon sx={{ mr: 1 }} />
              <span>Contact Us</span>
            </p>
          </li>

          {checkAuth() ? (
            <li onClick={() => logOut()}>
              <p>
                <ExitToAppRoundedIcon sx={{ mr: 1 }} />
                <span>Logout</span>
              </p>
            </li>
          ) : (
            <li onClick={() => logOut()}>
              <p>
                <LockOpenIcon sx={{ mr: 1 }} />
                <span>Login</span>
              </p>
            </li>
          )}
        </ul>
      </section>

      {/*content area */}
      <section className="content">
        <div className="content__header">
          <header>
            <button onClick={() => setShowMenu(true)}>
              <i className="icon-menu"></i>
            </button>
          </header>
          <nav>
            <h3>{pageTitle}</h3>
          </nav>
        </div>

        <div className="main_content">{children}</div>
      </section>
    </div>
  );
};

export default Layout;
