import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../../../../components/Modal/modal";
import { useDropzone } from "react-dropzone";
import Placeholder from "../../../../../assets/images/svg/excel-placholder.svg";
import "./style.scss";
import { selectUploadingFile } from "../../../../../redux/document/selectors";
import {
  fetchMyUploadedFiles,
  startManualUpload,
} from "../../../../../redux/document/actions";
import { convertBytes } from "../../../../../helpers";
import { selectprojects } from "../../../../../redux/project/selectors";
import TagsInput from "../../../../../components/Tags/tagsInput";
import {
  Alert,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const ManualuploadModal = ({ open, closeModal, title }) => {
  const userType = sessionStorage.getItem("user_type");
  const { Option } = Select;

  const dispatch = useDispatch();
  const uploading = useSelector(selectUploadingFile);
  const projects = useSelector(selectprojects);
  const [files, setFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [project, setProject] = useState("");

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".png", ".jpg"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".xls",
      ],
      "text/csv": [".csv"],
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-powerpoint": [".ppt", ".pot", ".pps", ".ppa"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".doc", ".docx"],
    },

    onDrop: (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
  });

  const upload = () => {
    dispatch(
      startManualUpload(
        {
          file: files,
          project: project,
          tags,
        },
        () => {
          reset();
          dispatch(fetchMyUploadedFiles());
        }
      )
    );
  };

  const reset = () => {
    setFiles([]);
    setTags([]);
    setProject("");
    closeModal();
  };

  const deleteFile = (index) => {
    const filesClone = [...files];
    filesClone.splice(index, 1);
    setFiles(filesClone);
  };

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <div className="modal_footer">
          <button
            disabled={uploading}
            className="cancel me-2"
            onClick={() => {
              reset();
            }}
          >
            Cancel
          </button>
          <button
            onClick={() => upload()}
            disabled={!files.length || uploading}
          >
            Upload
            <i
              className={
                uploading ? "icon-spin animate-spin ms-2" : "icon-upload ms-2"
              }
            />
          </button>
        </div>
      }
    >
      {userType && userType.toLowerCase() === "system_admin" && (
        <div
          className="project_selection_section"
          style={{
            marginBottom: "2px",
          }}
        >
          <FormControl
            style={{
              width: "100%",
              marginBottom: 1,
              marginTop: 1,
            }}
          >
            <InputLabel id="project-select-label">
              Select the project to store document
            </InputLabel>
            <Select
              labelId="project-select-label"
              id="project-select"
              value={project}
              label="Select the project to store document"
              onChange={(e) => setProject(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {(projects?.data || []).map((project, i) => (
                <MenuItem key={i} value={project?.id}>
                  {project?.name}
                </MenuItem>
              ))}
            </Select>
            {!projects && (
              <Alert
                severity="warning"
                sx={{
                  marginTop: 1,
                  marginBottom: 1,
                }}
              >
                There are no projects associated with your account. To submit
                this directly to a project, please request to be added to a
                project.
              </Alert>
            )}
          </FormControl>
        </div>
      )}
      <FormControl
        style={{
          width: "100%",
          marginTop: 1,
        }}
      >
        <TagsInput tags={tags} setTags={setTags} />
      </FormControl>
      <div
        className="dropzone_wrapper"
        style={{
          marginBottom: 10,
        }}
      >
        <span className="dzu-dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          <img src={Placeholder} alt="Excel" />
          <p style={{ margin: "20px" }}>
            {isDragActive
              ? "Drop the files here ..."
              : "Drag and drop your files here or click here to upload"}
          </p>
          <button>
            Browse file(s)
            <i className="icon-upload ms-2" />
          </button>
        </span>
        {files.length
          ? files.map((file, i) => (
              <div
                className="uploaded_file mt-3 d-flex align-items-center"
                key={i}
              >
                <div className="d-flex align-items-center">
                  <p className="me-2">{file.name}</p>
                  <span>{convertBytes(file.size)}</span>
                </div>
                <p className="cancel" onClick={() => deleteFile(i)}>
                  X
                </p>
              </div>
            ))
          : null}
      </div>
    </Modal>
  );
};

export default ManualuploadModal;
