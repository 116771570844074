import { put, takeEvery } from "redux-saga/effects";
import { updateUserSuccess, updateUserFailure } from "../actions";
import { notification } from "antd";
import request from "../../../utils/request";
import urls from "../../../utils/config";
import UserActionTypes from "../types";

const { API_BASE_URL } = urls || {};

export function* updateUser({
  payload: {
    id,
    firstName,
    lastName,
    email,
    phone,
    role,
    department,
    designation,
    cb,
  },
}) {
  const url = `${API_BASE_URL}/users/${id}`;

  const response = yield request({
    method: "put",
    data: {
      firstName,
      lastName,
      email,
      phone,
      roles: [role],
      department,
      designation,
    },
    url,
  });

  if (response.success) {
    yield put(updateUserSuccess());
    cb();
    notification.success({
      message: `Success`,
      description: "User updated successfully",
      placement: "topRight",
    });
  } else {
    notification.error({
      message: `Error`,
      description: "Something went wrong try again",
      placement: "topRight",
    });
    yield put(updateUserFailure("error"));
  }
}

export function* onUpdateUser() {
  yield takeEvery(UserActionTypes.UPDATE_USER_START, updateUser);
}
