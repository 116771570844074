import React, { createContext, useContext, useState } from "react";
import Cookies from "universal-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const [authToken, setAuthToken] = useState(cookies.get("authToken") || null);

  const checkAuth = () => {
    return cookies.get("authToken") !== null;
  };

  const getUserType = () => {
    return cookies.get("userType");
  }

  const getToken = () => {
    return cookies.get("authToken");
  };

  const login = (token) => {
    cookies.set("authToken", token, {
      path: "/",
      secure: true,
      sameSite: "strict",
      maxAge: 3600,
    });
    setAuthToken(token);
  };

  const logout = () => {
    cookies.remove("authToken", { path: "/" });
    setAuthToken(null);
  };

  return (
    <AuthContext.Provider
      value={{ authToken, login, logout, checkAuth, getToken, getUserType }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
