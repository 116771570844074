// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_container {
  width: 100%;
  min-height: 150px;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader_container .loader {
  border: 8px solid #f9f9f9;
  border-radius: 50%;
  border-top: 8px solid var(--primary-color);
  width: 80px;
  height: 80px;
  box-shadow: 1px 2px 8px rgba(107, 154, 19, 0.3); /* Safari */
  animation: spin 1s linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,yBAAA;EACA,kBAAA;EACA,0CAAA;EACA,WAAA;EACA,YAAA;EACA,+CAAA,EACA,WAAA;EACA,kCAAA;AAEJ","sourcesContent":[".loader_container {\n  width: 100%;\n  min-height: 150px;\n  height: inherit;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  & .loader {\n    border: 8px solid #f9f9f9;\n    border-radius: 50%;\n    border-top: 8px solid var(--primary-color);\n    width: 80px;\n    height: 80px;\n    box-shadow: 1px 2px 8px rgba(107, 154, 19, 0.3);\n    -webkit-animation: spin 1s linear infinite; /* Safari */\n    animation: spin 1s linear infinite;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
