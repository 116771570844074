import React from "react";
import "./assets/fontello/css/animation.css";
import ReactDOM from "react-dom/client";
import "./assets/fontello/css/fontello.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-tabs/style/react-tabs.css";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { AuthProvider } from "./components/Auth/authProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </AuthProvider>
);
