// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --black: #000;
  --white: #fff;
  --primary-bg: #f7f7f7;
  --primary-color: #6b9a13;
  --primary-font: "Albert Sans";
  --primary-input-label-color: rgb(68, 74, 88);
  --primary-input-border-color: rgb(216, 216, 216);
  --raleway: "Raleway", sans-serif;
  --arial-reg: "Arial-Reg", sans-serif;
  --arial-slim: "Arial-thin", sans-serif;
  --arial-thick: "Arial-thick", sans-serif;
  --arial-bold: "Arial-bold", sans-serif;
  --arial-black: "Arial-black", sans-serif;
}

.ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown-menu-title-content p {
  font-family: var(--primary-font);
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
}

.ant-dropdown-menu-title-content p img {
  width: 15px;
  margin-right: 10px;
}

button:disabled {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,gCAAA;EACA,mCAAA;EACA,kCAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,qBAAA;EACA,wBAAA;EACA,6BAAA;EACA,4CAAA;EACA,gDAAA;EAEA,gCAAA;EAGA,oCAAA;EACA,sCAAA;EACA,wCAAA;EACA,sCAAA;EACA,wCAAA;AAFF;;AAKA;EACE,UAAA;AAFF;;AAIA;EACE,gCAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAGA;EACE,WAAA;EACA,kBAAA;AAAF;;AAGA;EACE,oCAAA;EACA,oCAAA;EACA,yBAAA;EACA,8BAAA;AAAF","sourcesContent":["@import \"~antd/dist/antd.css\";\nbody {\n  margin: 0;\n  font-family: var(--primary-font);\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n:root {\n  --black: #000;\n  --white: #fff;\n  --primary-bg: #f7f7f7;\n  --primary-color: #6b9a13;\n  --primary-font: \"Albert Sans\";\n  --primary-input-label-color: rgb(68, 74, 88);\n  --primary-input-border-color: rgb(216, 216, 216);\n\n  --raleway: \"Raleway\", sans-serif;\n  // --manrope: \"Manrope\", sans-serif;\n  // --robot: \"Roboto\", sans-serif;\n  --arial-reg: \"Arial-Reg\", sans-serif;\n  --arial-slim: \"Arial-thin\", sans-serif;\n  --arial-thick: \"Arial-thick\", sans-serif;\n  --arial-bold: \"Arial-bold\", sans-serif;\n  --arial-black: \"Arial-black\", sans-serif;\n}\n\n.ant-dropdown-menu {\n  padding: 0;\n}\n.ant-dropdown-menu-title-content p {\n  font-family: var(--primary-font);\n  margin-bottom: 0;\n  display: flex;\n  align-items: center;\n  font-size: 13px;\n}\n.ant-dropdown-menu-title-content p img {\n  width: 15px;\n  margin-right: 10px;\n}\n\nbutton:disabled {\n  border: 1px solid #999999 !important;\n  background-color: #cccccc !important;\n  color: #666666 !important;\n  cursor: not-allowed !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
