import React from "react";
import { Chip, TextField, Autocomplete, Stack } from "@mui/material";

const TagsInput = ({ tags, setTags }) => {
  const handleTagChange = (event, newTags) => {
    setTags(newTags);
  };

  const tagSuggestions = [
    "Finance",
    "Health",
    "HR",
    "IT",
    "Marketing",
    "Sales",
    "SOP",
    "Operations",
    "Customer Service",
    "Engineering",
    "Product Management",
    "Design",
    "Legal",
    "Administration",
    "Research",
    "Development",
  ];

  return (
    <Stack
      spacing={3}
      sx={{
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      <Autocomplete
        multiple
        freeSolo
        id="tags-filled"
        options={tagSuggestions}
        value={tags}
        onChange={handleTagChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="filled" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Provide tags that describe the documents"
            placeholder="Add a tag"
            sx={{
              "& .MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          />
        )}
      />
    </Stack>
  );
};

export default TagsInput;
