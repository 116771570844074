import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../../components/Modal/modal";
import { updateUserStart } from "../../../../../redux/users/actions";
import { selectUpdatingUser } from "../../../../../redux/users/selectors";

const validationSchema = Yup.object({
  firstName: Yup.string().required("Enter User's Name"),
  lastName: Yup.string().required("Enter User's Name"),
  email: Yup.string().email("Invalid email address").required("Enter Email"),
  phone: Yup.string()
    .optional()
    .matches(/^\+?[0-9]{10,15}$/, "Phone number is not valid"),
  role: Yup.string().required("Select a user role"),
  department: Yup.string().optional("Select a department"),
  designation: Yup.string().optional(),
});

const EditUserModal = ({ open, closeModal, title, callBack, user }) => {
  const dispatch = useDispatch();
  const updatingUser = useSelector(selectUpdatingUser);

  console.log(user);

  const formik = useFormik({
    initialValues: {
      id: user?.id,
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      phone: user?.phone || undefined,
      role: user?.roles[0] || "",
      department: user?.department || "",
      designation: user?.designation || "",
    },
    enableReinitialize: true, // Ensures form values update when `user` changes
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      dispatch(
        updateUserStart(
          values.id,
          values.firstName,
          values.lastName,
          values.email,
          values.phone,
          values.role,
          values.department,
          values.designation,
          () => {
            callBack();
            formik.resetForm();
          }
        )
      );
    },
  });

  return (
    <Modal
      open={open}
      closeModal={closeModal}
      title={title}
      modalFooter={
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
          className="modal_footer"
        >
          <Button
            variant="outlined"
            onClick={() => {
              closeModal();
              formik.resetForm();
            }}
            disabled={updatingUser}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid || updatingUser}
            endIcon={
              updatingUser ? (
                <LoopIcon className="animate-spin" fontSize="medium" />
              ) : (
                <SaveRoundedIcon fontSize="medium" />
              )
            }
          >
            Save
          </Button>
        </Box>
      }
    >
      <Box mt={4} mb={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="First Name"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.errors.firstName}
              helperText={formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Last Name"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.errors.lastName}
              helperText={formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.errors.email}
              helperText={formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Phone Number"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.errors.phone}
              helperText={formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formik.errors.role}>
              <InputLabel>User Role</InputLabel>
              <Select
                name="role"
                value={formik.values.role}
                onChange={formik.handleChange}
              >
                <MenuItem value="GUEST">GUEST</MenuItem>
                <MenuItem value="NEW_STAFF">NEW STAFF</MenuItem>
                <MenuItem value="PROGRAM_OPERATION_STAFF">
                  PROGRAM OPERATION STAFF
                </MenuItem>
                <MenuItem value="MANAGEMENT_STAFF">MANAGEMENT STAFF</MenuItem>
                <MenuItem value="SYSTEM_ADMIN">SYSTEM ADMIN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formik.errors.department}>
              <InputLabel>Department</InputLabel>
              <Select
                name="department"
                value={formik.values.department}
                onChange={formik.handleChange}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Audit">Audit</MenuItem>
                <MenuItem value="Finance">Finance</MenuItem>
                <MenuItem value="HR">HR</MenuItem>
                <MenuItem value="IT">IT</MenuItem>
                <MenuItem value="Project Team">Project Team</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Designation"
              name="designation"
              value={formik.values.designation}
              onChange={formik.handleChange}
              fullWidth
              error={!!formik.errors.designation}
              helperText={formik.errors.designation}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditUserModal;
