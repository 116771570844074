// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty_state_container {
  width: 100%;
  height: 90%;
  padding: 50px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}
.empty_state_container p {
  margin: 0;
  margin-top: 20px; /* Adjust the margin-top as needed */
  font-family: var(--arial-slim);
  text-align: center; /* Center-align the text */
}
.empty_state_container button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  min-width: 140px;
  color: var(--white);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0px 1rem;
  font-weight: 600;
  font-size: 13px;
  height: 40px;
  margin-top: 25px;
}
.empty_state_container button:hover {
  background-color: var(--primary-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/EmptyState/empty-state.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,gCAAA;AACF;AACE;EACE,SAAA;EACA,gBAAA,EAAA,oCAAA;EACA,8BAAA;EACA,kBAAA,EAAA,0BAAA;AACJ;AAEE;EACE,sCAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AAAJ;AAEI;EACE,iDAAA;AAAN","sourcesContent":[".empty_state_container {\n  width: 100%;\n  height: 90%;\n  padding: 50px 5%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.2s ease-in-out;\n\n  p {\n    margin: 0;\n    margin-top: 20px; /* Adjust the margin-top as needed */\n    font-family: var(--arial-slim);\n    text-align: center; /* Center-align the text */\n  }\n\n  button {\n    background-color: var(--primary-color);\n    border: none;\n    border-radius: 5px;\n    box-sizing: border-box;\n    min-width: 140px;\n    color: var(--white);\n    align-items: center;\n    display: flex;\n    justify-content: center;\n    padding: 0px 1rem;\n    font-weight: 600;\n    font-size: 13px;\n    height: 40px;\n    margin-top: 25px;\n\n    &:hover {\n      background-color: var(--primary-color) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
