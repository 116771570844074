import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input, notification } from "antd";
import axios from "axios";
import "../../Pages/Signin/Signin.scss";
import urls from "../../utils/config";
import request from "../../utils/request";
import { useAuth } from "./authProvider";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const { API_BASE_URL } = urls || {};

const AdminSignin = ({ setShowOtp, setAccount }) => {
  const { login, getToken } = useAuth();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      submit(values);
    },
    validateOnMount: false,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchema,
  });

  // Redirect user to overview page when logged in
  useEffect(() => {
    const token = getToken();
    if (token) {
      window.location.href = "/overview";
    }
  }, []);

  const submit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(`${API_BASE_URL}/auth/sign-in`, data);
      if (res.status === 200 || res.status === 201) {
        if (res.data.data.accessToken) {
          login(res.data.data.accessToken);
          // get user data
          const sessionResponse = await request({
            method: "get",
            url: `${API_BASE_URL}/users/me`,
          });
          if (sessionResponse.success) {
            sessionStorage.setItem(
              "user_type",
              sessionResponse.raw.data.roles[0]
            );
            setLoading(false);
            window.location.reload();
            return;
          } else {
            setLoading(false);
            throw new Error(sessionResponse.raw.data.error.message);
          }
        }
        setShowOtp(true);
        setAccount("system_admin");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      notification.error({
        message: `Error`,
        description:
          err.response?.data?.error?.message ||
          "Something went wrong try again",
        placement: "topRight",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div className="form__body">
        <div className="form">
          <div className="input__container">
            <label htmlFor="email">Email address</label>
            <Input
              name="email"
              placeholder="Email Address"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <p className="error">{formik.errors.email}</p>
          </div>
          <div className="input__container">
            <label htmlFor="password">Password</label>
            <Input.Password
              name="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <p className="error">{formik.errors.password}</p>
          </div>
          <div className="input__container">
            <button
              disabled={loading}
              type="button"
              onClick={formik.handleSubmit}
            >
              {loading ? <i className="icon-spin animate-spin" /> : "Log In"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSignin;
