import ProjectActionTypes from "./types";

// set any data
export const setAnyProjectData = (payload) => ({
  type: ProjectActionTypes.SET_ANY_DATA,
  payload,
});

// fetch projects
export const startFetchProjects = (
  page = 1,
  limit = 200,
  order = "asc",
  search,
  category
) => ({
  type: ProjectActionTypes.FETCH_PROJECTS_START,
  payload: { page, limit, order, search, category },
});

export const fetchProjectsSuccess = (projects) => ({
  type: ProjectActionTypes.FETCH_PROJECTS_SUCCESS,
  payload: projects,
});

export const fetchProjectsFailure = (error) => ({
  type: ProjectActionTypes.FETCH_PROJECTS_ERROR,
  payload: error,
});

// create project
export const startCreateProject = (name, description, category, cb) => ({
  type: ProjectActionTypes.CREATE_PROJECT_START,
  payload: { name, description, category, cb },
});

export const createProjectSuccess = () => ({
  type: ProjectActionTypes.CREATE_PROJECT_SUCCESS,
});

export const createProjectFailure = (error) => ({
  type: ProjectActionTypes.CREATE_PROJECT_ERROR,
  payload: error,
});

// update project
export const updateProjectStart = (id, name, description, category, cb) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_START,
  payload: { id, name, description, category, cb },
});

export const updateProjectSuccess = () => ({
  type: ProjectActionTypes.UPDATE_PROJECT_SUCCESS,
});

export const updateProjectFailure = (error) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_ERROR,
  payload: error,
});

// update members
export const updateProjectMembersStart = (id, projectMembersIDs, cb) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_START,
  payload: { id, projectMembersIDs, cb },
});

export const updateProjectMembersSuccess = () => ({
  type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_SUCCESS,
});

export const updateProjectMembersFailure = (error) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_MEMBERS_ERROR,
  payload: error,
});

// update manager
export const updateProjectManagersStart = (id, projectManagersIDs, cb) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_MANAGERS_START,
  payload: { id, projectManagersIDs, cb },
});

export const updateProjectManagerSuccess = () => ({
  type: ProjectActionTypes.UPDATE_PROJECT_MANAGERS_SUCCESS,
});

export const updateProjectManagerFailure = (error) => ({
  type: ProjectActionTypes.UPDATE_PROJECT_MANAGERS_ERROR,
  payload: error,
});

// delete project
export const deleteProjectStart = (id, cb) => ({
  type: ProjectActionTypes.DELETE_PROJECT_START,
  payload: { id, cb },
});

export const deleteProjectSuccess = () => ({
  type: ProjectActionTypes.DELETE_PROJECT_SUCCESS,
});

export const deleteProjectFailure = (error) => ({
  type: ProjectActionTypes.DELETE_PROJECT_ERROR,
  payload: error,
});
