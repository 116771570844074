import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../components/Auth/authProvider";

const PrivateRoutes = () => {
  const { checkAuth, authToken } = useAuth();

  return authToken ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
